@import "../../theme/variables";

.patientlist_container {
    width: 100%;
    min-height: calc(100vh - 112px);
    background-color: $LightestBlue;

    .filter_wrapper{
        width: 100%;
        box-sizing: border-box;
        box-shadow: 3px 0 15px 0 rgba($BlackColor,0.03);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        padding: 15px;
        margin-bottom: 30px;
        background-color: $WhiteColor;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;

        .custom_field{
            margin: 0 15px 0 0;

            .MuiInputBase-input{
                height: 40px !important;
                padding: 6px 12px !important;
                box-sizing: border-box;
                font-size: 14px;
            }
        }

        .search_btn{
            font-size: 14px;
            height: 40px;
            padding: 11px 30px;
            min-width: auto;
            margin: 0 10px 0 0;
        }
    }

    .patient_table{
        width: 100%;
        margin: 0 0 30px;
        display: flex;

        .MuiTableContainer-root{
            box-shadow: 3px 0 15px 0 rgba($BlackColor,0.03);
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
        }

        .MuiTableCell-head{
            text-transform: uppercase;
            color: $secondaryColor;
            opacity: 0.6;
            font-size: 14px;
            font-weight: 600;
            white-space: nowrap;
        }

        .MuiIconButton-root{
            color: $ArrowColor;
            border: 1px solid $ArrowColor;
            padding: 0;
        }

        .table_name{
            color: $PrimaryColor;
            cursor: pointer;
        }

        .table_data{
            color: $secondaryColor ;
            opacity: 0.7;            
            font-weight: 400;
        }

        .status_active{
            text-transform: capitalize;
            background-color: rgba($GreenColor, 0.1);
            padding: 5px;
            display: inline-block;
            width: 77px;
            text-align: center;
            border: 1px solid $GreenBorder;
            color: $GreenColor;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            -o-border-radius: 4px;
        }
        .status_inactive{
            text-transform: capitalize;
            background-color: rgba($GreyColor, 0.1);
            padding: 5px;
            display: inline-block;
            width: 77px;
            text-align: center;
            border: 1px solid $GreyBorder;
            color: $GreyColor;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            -o-border-radius: 4px;
        }

        .expand_table{
            background-color: $ExpandBg;

            .report_id{
                color: $PrimaryColor;
                font-weight: 600;
                cursor: pointer;
            }

            .MuiTableCell-root{
                border-top: 1px solid $TableBorder;
                border-bottom: 0;
            }
        }

        .pagination_wrapper{
            padding: 20px;
            text-align: center;
            position: relative;
          
            .MuiPagination-ul{
                display: inline-flex;
                margin: 0 auto;

                .MuiPaginationItem-page{
                    color: $Pagination;
                    &.Mui-selected{
                        color: $WhiteColor;
                        background-color: $PaginationActive;
                    }
                }
            }

            .result_wrapper{
                position: absolute;
                right:25px;
                bottom: 25px;
                display: flex;
                align-items: center;
                color: $secondaryColor;
                opacity: 0.7;

                .MuiOutlinedInput-input{
                    padding: 5px 32px 5px 14px;
                    font-size: 14px;
                    font-weight: 600;
                    color: $secondaryColor;
                }
            }
        }
    }    
}

.patientdetail_container{
    width: 100%;
    min-height: calc(100vh - 112px);
    background-color: $LightestBlue;

    .profile_card{
        background-image: url('../../assets/images/profile_bg.png');
        background-repeat: no-repeat;
        background-position:bottom right;
        padding: 70px 0 !important;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .status_active{
        text-transform: capitalize;
        background-color: rgba($GreenColor, 0.1);
        padding: 5px;
        display: inline-block;
        width: 77px;
        text-align: center;
        border: 1px solid $GreenBorder;
        color: $GreenColor;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
    .status_inactive{
        text-transform: capitalize;
        background-color: rgba($GreyColor, 0.1);
        padding: 5px;
        display: inline-block;
        width: 77px;
        text-align: center;
        border: 1px solid $GreyBorder;
        color: $GreyColor;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }

    .profile_detail_list{
        list-style: none;
        padding: 20px;
        margin: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        
        li {
            min-width: calc(33% - 30px);
            padding-bottom: 20px;
            margin: 0 0 30px;
            border-bottom: 1px solid $Divider; 

            &:last-child{
                border: 0;
                padding-bottom: 0;
                margin: 0;
            }

            .font-14{
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;
                color: rgba(#999999, 0.8);
            }
        }
    }

    .report_table{
        width: 100%;
        margin: 0 0 30px;
        display: flex;

        .table_header{
            padding: 15px 20px;
            align-items: center;
            border-bottom: 1px solid #E0E0E0;

            .MuiOutlinedInput-root{
                font-size: 14px;
                font-weight: 400;
                height: 40px;
                padding-right: 0;
                box-shadow: none;
                border-radius: 20px;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
            }
            .MuiSvgIcon-root{
                fill: rgba(#393F51, 0.6);
            }
        }

        .MuiTableContainer-root{
            box-shadow: 3px 0 15px 0 rgba($BlackColor,0.03);
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
        }

        .MuiTableCell-head{
            text-transform: uppercase;
            color: $secondaryColor;
            opacity: 0.6;
            font-size: 14px;
            font-weight: 600;
            white-space: nowrap;
        }

        .table_name{
            color: $PrimaryColor;
            cursor: pointer;
        }

        .table_data{
            color: $secondaryColor ;
            opacity: 0.7;            
            font-weight: 400;
        }

        .MuiButton-root{
            color: $PrimaryColor;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: normal;
            padding: 8px 14px;
            min-width: 100px;
            white-space: nowrap;
        }

        .MuiButton-containedPrimary{
            color: $WhiteColor;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: normal;
            padding: 8px 14px;
            min-width: 100px;
            white-space: nowrap;
        }
    }      
}

.confirm_dialog{
    .MuiDialog-paperWidthSm{
        width: 450px;
        max-width: 90%;
    }
    .MuiDialogTitle-root{
        padding: 30px;
    }
    .MuiDialogActions-root{
        padding:30px;
        
        .MuiButtonBase-root{
            padding: 10px 30px;
        }
    }
}

.preview_dialog{
    .MuiDialog-paperWidthSm{
    width: 800px;
    max-width: 90%;
    }
   }

@media (min-width: 320px) and (max-width: 640px) {

    .patientlist_container {   
        .filter_wrapper{
            flex-wrap: wrap;

            .custom_field{
                margin: 0 0 20px;
            }    
        }    
        .patient_table{   
            .pagination_wrapper{                
                .result_wrapper{
                    margin-top: 15px;
                    position: static;
                    justify-content: flex-end;
                    width: 100%;
                }
            }
        }            
    }

    .patientdetail_container{
        .report_table{
            .MuiButton-root{
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
        .profile_detail_list{
            
            li {
                min-width: 100%;
                margin-bottom: 20px;

                &:last-child{
                    border: 0;
                    padding-bottom: 0;
                    margin: 0;
                }
            }
        }
    }
}