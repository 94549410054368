
$LightestBlue: #F5F6FA;
$WhiteColor: #ffffff;
$BlackColor: #000000;
$PrimaryColor: #3B2F66;
$secondaryColor: #263238;
$GreenColor: #25A63F;
$GreenBorder: #B9E4C0;
$GreyColor: #6A6A6A;
$GreyBorder: #CACACA;
$ArrowColor: #B8B4CC;
$ExpandBg: #FDFCFF;
$TableBorder: #D8D8D8;
$Pagination: #616161;
$PaginationActive: #47A1B6;
$Divider: #EEEDEF;