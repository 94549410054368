@import "../../theme/variables";
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

.login_container {
    min-height: 100vh;
    width: 100%;
    background-color: #ffffff;
    background-position: bottom right;
    background-repeat: no-repeat;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.confirm_container {
    min-height: 120vh;
    width: 100%;
    background-color: #ffffff;
    background-position: bottom right;
    background-repeat: no-repeat;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.login_btn_provider {
    padding: 18px;
    height: 56px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    color: #fff;
    background-color: #3B2F66;
    text-transform: uppercase;
}
  
.login_btn_patient {
    padding: 18px;
    height: 56px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    color: #fff;
    background-color: #005f82;
    text-transform: uppercase;
}

.brand_logo {
    max-width: 313.7px;
    width: 100%;
    height: auto;
    margin: auto;
    display: block;
}

.line-spacer {
    height:670px;
    width: 0px;
    background-color: #707070;
    margin: 0 20px;
    display: inline-block;
}

.subtitle {
    top: 258px;
    left: 743px;
    width: 435px;
    height: 50px;
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 30px;
    letter-spacing: 0.3px;
    color: #000000;
    opacity: 1;
}

.login_windows {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.custom_container {
    background-color: #ffffff;
    border: 1px solid #707070;
    width: 599px;
    height: 670px;
    max-width: 90%;
    border-radius: 10px;
    display: inline-block;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.confirm_container {
    background-color: #ffffff;
    border: 1px solid #707070;
    width: 599px;
    height: 680px;
    max-width: 90%;
    border-radius: 10px;
    display: inline-block;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.validation-form {
    height: 100%;
}

.provider {
    height: 100%;
    padding: 40px 45px;
}

.forgot-password {
    height: 100%;
    padding: 102px 45px;
}

.mfa-select-container{
    background-color: #ffffff;
    border: 1px solid #707070;
    width: 600px;
    height: 850px;
    max-width: 90%;
    border-radius: 10px;
    display: inline-block;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.mfa-select{
    height: 100%;
    padding: 45px 50px;
}

.patient-forgot-password {
    height: 100%;
    padding: 58px 50px;
}

.patient {
    height: 100%;
    padding: 40px 33px;
}

.patient-text {
    color: #005F82;
}

.text-h4 {
    font-size: 48px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    line-height: 1.235;
    text-align: center;
}

.text-h5 {
    font-size: 24px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    line-height: 1.235;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
}

.forgot-password-title {
    font-size: 40px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    line-height: 1.235;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
}

.forgot-password-text {
    font-size: 20px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    line-height: 1.235;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
}

.confirmation-text {
    font-size: 24px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    line-height: 1.235;
    text-align: center;
    vertical-align: center;
    color: rgb(0, 0, 0);
}

.confirmation-code {
    padding-left: 7px;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
}

.dialogCloseIcon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialogContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.password-changed-title {
    font-size: 40px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    line-height: 1.235;
    text-align: center;
    color: rgba(74, 137, 40, 1);
}

.help-title {
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 28px;
}

.confirm-dialog{
    .MuiDialog-paperWidthSm{
        width: 700px;
        max-width: 90%;
        height: 300px;
        max-width: 90%;
    }
    .MuiDialogTitle-root{
        padding: 30px;
    }
    .MuiDialogActions-root{
        padding:30px;
        
        .MuiButtonBase-root{
            padding: 10px 30px;
        }
    }
}

@media (min-width:320px) and (max-width: 640px) {
    .login_container {
        .custom_container{    
            padding:20px;
            width: 100%;
            max-width: 300px;
        }
    }
}

@media screen and (max-width: 1230px) {
    .login_windows {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
    }
    
    .custom_container {
        width: 80%;
    }
    
    .line-spacer {
        height: 1px;
        width: 60%;
        background-color: #707070;
        margin: 40px 0px;
        display: inline-block;
    }
}

@media (min-width:320px) and (max-width: 640px) {
    .custom_container{    
        padding:20px;
        width: 100%;
        max-width: 300px;
    }
}